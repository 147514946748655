import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../admin.css';

const AdminDashboard = () => {
  const [newMotor, setNewMotor] = useState({
    name: '',
    description: '',
    price: '',
    type: '',
    image: '',
    model:'',
    engine_number:'',
    mileage:'',
    images:'',
    engine_size:'',
    fuel_type:'',
    
  });

  const [motors, setMotors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch existing motors from the backend
    fetch('https://api.pemire.co.ke/motors')
      .then(response => response.json())
      .then(data => setMotors(data))
      .catch(error => console.error('Error fetching motors:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
   
    setNewMotor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const formatPrice = (value) => {
    // Remove all non-numeric characters except for the "M" or "K" suffix
    const numericValue = value.replace(/[^0-9MK]/g, '').toUpperCase();
  
    if (numericValue.includes('M')) {
      // If the value includes "M", multiply the numeric part by 1,000,000
      return parseFloat(numericValue.replace('M', '')) * 1000000;
    } else if (numericValue.includes('K')) {
      // If the value includes "K", multiply the numeric part by 1,000
      return parseFloat(numericValue.replace('K', '')) * 1000;
    } else {
      // Otherwise, return the numeric value as it is
      return parseFloat(numericValue);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Retrieve access token from local storage
    const accessToken = localStorage.getItem('accessToken');
    const formattedPrice = formatPrice(newMotor.price);
  
    // Check if access token exists
    if (!accessToken) {
      console.error('not authorized');
      // Handle case where access token is missing
      return;
    }
  
    // Prepare new motor data with formatted price
    const motorData = {
      ...newMotor,
      price: formattedPrice,
    };
  
    // Send new motor data to the backend
    fetch('https://api.pemire.co.ke/motors', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`, // Include access token in the authorization header
      },
      body: JSON.stringify(motorData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the motors list with the newly added motor
        setMotors((prevMotors) => [...prevMotors, data]);
        // Reset the newMotor state
        setNewMotor({
          name: '',
          description: '',
          price: '',
          image: '',
          model: '',
          engine_number: '',
          mileage: '',
          
          engine_size: '',
          fuel_type: '',
          type: '',
          // Reset other fields as needed
        });
      })
      .catch((error) => console.error('Error adding new motor:', error));
  };
  
  const handleEdit = (id) => {
    navigate(`/edit-car/${id}`);
  };
  const handleDelete = (motorId) => {
    const accessToken = localStorage.getItem('accessToken')
    fetch(`https://api.pemire.co.ke/motors/${motorId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Include access token in the authorization header
      },
    })
      .then(response => {
        if (response.ok) {
          // Remove the deleted motor from the motors list
          setMotors(prevMotors => prevMotors.filter(motor => motor.id !== motorId));
        } else {
          throw new Error('Failed to delete motor');
        }
      })
      .catch(error => console.error('Error deleting motor:', error));
  };
  // (name, image, type, description, price, model, engine_number, mileage, images, engine_size, fuel_type) 
  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-4">Admin Dashboard</h1>

      {/* Form for adding new motor */}
      <form onSubmit={handleSubmit} className="mb-8">
        <input
          type="text"
          name="name"
          value={newMotor.name}
          onChange={handleInputChange}
          placeholder="Name"
          className="input mb-2"
        />
        <input
          type="text"
          name="description"
          value={newMotor.description}
          onChange={handleInputChange}
          placeholder="Description"
          className="input mb-2"
        />
     
        <input
          type="text"
          name="type"
          value={newMotor.type}
          onChange={handleInputChange}
          placeholder="type"
          className="input mb-2"
        />
        <input
          type="text"
          name="price"
          value={newMotor.price}
          onChange={handleInputChange}
          placeholder="Price"
          className="input mb-2"
        />
        <input
          type="text"
          name="image"
          value={newMotor.image}
          onChange={handleInputChange}
          placeholder="Paste your image address here..."
          className="input mb-2"
        />
      
        <input
          type="number"
          name="model"
          value={newMotor.model}
          onChange={handleInputChange}
          placeholder="model"
          className="input mb-2"
        />
        
        <input
          type="text"
          name="mileage"
          value={newMotor.mileage}
          onChange={handleInputChange}
          placeholder="mileage"
          className="input mb-2"
        />
        <input
          type="text"
          name="fuel_type"
          value={newMotor.fuel_type}
          onChange={handleInputChange}
          placeholder="fuel_type"
          className="input mb-2"
        />
        {/* Add more input fields for other motor details */}
        <button type="submit" className="btn btn-primary">Add Motor</button>
      </form>

      {/* List of existing motors with delete buttons */}
      <div className="grid grid-cols-1 gap-4" >
        {motors.map(motor => (
          <div key={motor.id} className="bg-white p-4 rounded shadow" >
            <div className='flex items-center flex-col'>
              <h2 className="text-lg font-semibold mb-2">{motor.name}</h2>
              <p className="text-gray-600 mb-2">{motor.description}</p>
              <p className="text-gray-600 mb-2">{motor.price}</p>
            </div>
            <div className='flex justify-center'>
              <img src={motor.image} alt={motor.name} className="w-1/4 mb-2" />
            </div>
            <div className='flex justify-around'>
              <button onClick={() => handleEdit(motor.id)} className="btn btn-edit">
                Edit
              </button>
              <button onClick={() => handleDelete(motor.id)} className="btn btn-delete">
              Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;