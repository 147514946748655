import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';

const ViewCarDetails = () => {
  const location = useLocation();
  const [motor, setMotor] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const motorId = location.pathname.split('/').pop();
    fetch(`https://api.pemire.co.ke/motors/${motorId}`)
      .then(response => response.json())
      .then(data => setMotor(data))
      .catch(error => console.error('Error fetching motor details:', error));
  }, [location.pathname]);

  const handleNextImage = () => {
    setCurrentImageIndex(prevIndex => prevIndex + 1);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(prevIndex => prevIndex - 1);
  };

  if (!motor) {
    return <span className="loading loading-infinity loading-lg"></span>;
  }

  return (
    <div className="flex justify-center mt-6">
      <div class="w-500 p-4 bg-white rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out">
        <div class="relative">
          {/* Display current image */}
          <img class="w-full h-auto object-cover rounded-t-lg" alt={motor.name} src={motor.images[currentImageIndex]?.image_url || motor.image} />
          {/* Arrow buttons */}
          <button class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-200 text-gray-600 rounded-full p-2 focus:outline-none" onClick={handlePreviousImage}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-200 text-gray-600 rounded-full p-2 focus:outline-none" onClick={handleNextImage}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
          {/* Car details */}
          <div class="p-4">
            <h2 class="text-xl font-semibold">{motor.name}</h2>
            <p class="text-gray-600">Description:{motor.description}</p>
            <p><strong>Price:</strong> {motor.price}</p>
            <p><strong>model:</strong> {motor.model}</p>
            <p><strong>mileage:</strong> {motor.mileage}</p>
            
            <div class="flex justify-between items-center mt-4">
              <button className="bg-[#F58634] hover:bg-blue-600 text-white px-7 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400" href="mailto:Pemireinsurance@pemire.co.ke" >Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCarDetails;
