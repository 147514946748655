import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function EditCar() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [formData, setFormData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [images, setImages] = useState([]);

  const categories = ['name', 'description', 'price', 'image',  'fuel_type', 'model'];

  useEffect(() => {
    // Fetch car details from backend based on ID
    fetch(`https://api.pemire.co.ke/motors/${id}`)
      .then(response => response.json())
      .then(data => {
        setCar(data);
        setImages(data.images);
      })
      .catch(error => console.error('Error fetching car details:', error));
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem('accessToken')
    fetch(`https://api.pemire.co.ke/motors/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        window.alert('Car updated successfully');
        // Optionally, navigate back to admin dashboard
      })
      .catch(error => console.error('Error updating car:', error));
  };

  const handleImageUpload = async () => {
    // Check if the images state variable is defined
    if (!images) {
      setImages([]);
    }
  
    // Check if the number of selected images plus existing images exceeds 5
    if (images.length > 4) {
      window.alert("Maximum images reached");
      return;
    }
  
    // Add newly uploaded images to the existing images array
    const newImages = [];
    for (let i = 0; i < 5 - images.length; i++) {
      const imageUrl = formData[`image_${i}`];
      if (imageUrl) {
        newImages.push({ url: imageUrl });
      }
    }
  
    if (newImages.length === 0) {
      window.alert("No images provided");
      return;
    }
  
    setImages(prevImages => [...prevImages, ...newImages]);
  
    // Make a POST request to add the new images to the backend
    try {
      const response = await fetch(`https://api.pemire.co.ke/motors/${id}/images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({ image_urls: newImages.map(image => image.url) })
      });
  
      if (!response.ok) {
        window.alert('Failed to upload images');
      }
  
      // Optionally, handle successful upload
    } catch (error) {
      window.alert('Error uploading images:', error);
      // Optionally, handle error
    }
  };
  
  
  

  const handleImageDelete = async (index) => {
    // Get the ID of the image to be deleted
    const imageId = images[index].id;
    
  
    // Remove the image at the specified index from the images array
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  
    try {
      // Make a DELETE request to delete the image from the backend
      const response = await fetch(`https://api.pemire.co.ke/images/${imageId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          // Include any necessary headers, such as authorization headers
        },
      });
  
      if (!response.ok) {
        window.alert('Failed to delete image');
      }
    } catch (error) {
      window.alert('Error deleting image:', error);
      // Optionally, handle error
    }
  };

  if (!car) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Edit Car</h2>
      <div className="flex flex-row-reverse">
        <div className='flex flex-row-reverse justify-around'>
          <img src={car.image} alt={car.name} className="w-1/2" />
          <div className="w-1/2 flex flex-col justify-center">
            <h3 className="text-xl font-semibold mb-2">{car.name}</h3>
            <p>Description: {car.description}</p>
            <p>Price: {car.price}</p>
            <p>Model: {car.model}</p>
            <p>Fuel:{car.fuel_type}</p>
            <p>Model:{car.model}</p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className='flex justify-center flex-col'>
          <label htmlFor="category" className="block">Select Category:</label>
          <select id="category" className="border rounded-md p-2 mt-2" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">Select Category</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        {selectedCategory && (
          <div className='flex justify-center'>
            <label htmlFor="value" className="block mt-4">Enter Value:</label>
            <input type="text" id="value" name={selectedCategory} value={formData[selectedCategory] || ''} onChange={handleChange} className="border rounded-md p-2 mt-2" />
          </div>
        )}
        <div className='flex justify-center'>
          <button type="submit" className="bg-[#3b82f6] hover:bg-[#5093ff] text-white px-12 py-2 rounded-md mt-2">Update</button>
        </div>
      </form>
      {/* Image Upload Section */}
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-4">Car Images</h3>
        <form id="imageForm" onSubmit={(e) => { e.preventDefault(); handleImageUpload(); }} className="flex flex-wrap">
          {images.map((image, index) => (
            <div key={index} className="relative mr-4 mb-4">
              <img src={image.url} alt="img" className="w-40 h-40 object-cover rounded-lg" />
              <button className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full" onClick={() => handleImageDelete(index)}>x</button>
            </div>
          ))}
          {Array.from({ length: 5 - images.length }).map((_, index) => (
            <div key={index} className="mr-4 mb-4">
              <input
                type="text"
                placeholder="Enter Image URL"
                value={formData[`image_${index}`] || ''}
                onChange={(e) => setFormData({ ...formData, [`image_${index}`]: e.target.value })}
                className="border rounded-md p-2 mr-2"
              />
            </div>
          ))}
          {images.length < 5 && (
            <button type="submit" form="imageForm" className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2">Add Images</button>
          )}
        </form>
      </div>
    </div>
  );
} 

export default EditCar
