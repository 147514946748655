import React from 'react';
import { FacebookIcon, TwitterIcon, InstagramIcon } from './FontAwesomeIcons';
import { Link } from 'react-router-dom';
import '../App.css';


function Footer() {
 

  return (
    <div >
    <div className="footer">
          <div className="contact-support">
            <h2>Contact Customer Support</h2>
            <p>Please choose your preferred method of contact:</p>
            <ul>
              
              <li>
                <strong>Email:</strong> Send an email to <a href="mailto:Pemireinsurance@pemire.co.ke">Pemireinsurance@pemire.co.ke</a>.
              </li>
              <li>
                <strong>Phone:</strong> Call our support hotline at <a href="tel:0734691090">0724 017 913</a>.
              </li>
            </ul>
          </div>
    
          <div className="social-icons">
                <a href="https://www.facebook.com/pemirecars" ><FacebookIcon /> Pemire Motors</a>
                <a href="https://www.facebook.com/profile.php?id=100057069625326" ><FacebookIcon />Pemire Insurance</a>
                <a href="" ><TwitterIcon /> X</a>
                <a href="Instagram" ><InstagramIcon /> Instagram</a>
                
                <Link to='/login'>
                <p>&copy;2024 Pemire Insurance <script>document.write (Date.getFullYear())</script>
                </p>
                </Link>
              </div>
        
          </div>
        </div>
    
  );
}

export default Footer;



