import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCar, faStar } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

const HomePage = () => {
  const [typedText, setTypedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  const textSpeed = 100;
  useEffect(() => {
    let currentIndex = 0;
    let timeoutId;

    const typeText = () => {
      if (currentIndex === 6) {
        setIsTyping(false);
        timeoutId = setTimeout(deleteText, 1000); // Wait for 1 second before deleting
        return;
      }

      setTypedText((prevText) => {
        currentIndex++;
        return 'Group'.substring(0, currentIndex);
      });
      timeoutId = setTimeout(typeText, textSpeed);
    };

    const deleteText = () => {
      if (currentIndex === 0) {
        setIsTyping(true);
        timeoutId = setTimeout(typeInsurance, 1000); // Wait for 1 second before typing Insurance
        return;
      }

      setTypedText((prevText) => {
        currentIndex--;
        return 'Group'.substring(0, currentIndex);
      });
      timeoutId = setTimeout(deleteText, textSpeed);
    };

    const typeInsurance = () => {
      if (currentIndex === 9) {
        setIsTyping(false);
        timeoutId = setTimeout(deleteInsurance, 1000); // Wait for 1 second before deleting
        return;
      }

      setTypedText((prevText) => {
        currentIndex++;
        return 'Insurance'.substring(0, currentIndex);
      });
      timeoutId = setTimeout(typeInsurance, textSpeed);
    };

    const deleteInsurance = () => {
      if (currentIndex === 0) {
        setIsTyping(true);
        timeoutId = setTimeout(typeText, 1000); // Wait for 1 second before typing Group again
        return;
      }

      setTypedText((prevText) => {
        currentIndex--;
        return 'Insurance'.substring(0, currentIndex);
      });
      timeoutId = setTimeout(deleteInsurance, textSpeed);
    };

    typeText();

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <img
        src="https://media.istockphoto.com/id/1199060494/photo/insurance-protecting-family-health-live-house-and-car-concept.jpg?b=1&s=612x612&w=0&k=20&c=rShYMRajPvg2S4u84fR-wNzJjhelKMCeY8HrTMNJQ-c="
        alt="insurance"
        className="object-cover w-full h-96"
      />
      <h2 className="text-4xl font-bold mb-4 text-[#424242] text-center">
        Welcome to Pemire {typedText}
        <span className="text-blue-500 text-4xl mb-4">
          <span className={isTyping ? 'cursor' : 'cursor-hidden'}></span>
        </span>
      </h2>
      <p className="text-lg text-[#5093ff] text-center">Your Satisfaction, Our Joy.</p>
      {/* Welcome Section */}
      <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center"></div>

      {/* Content */}
      <div className="text-white container mx-auto py-12 md:container">
        {/* Services Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 mt-8 w-full">
          {/* Insurance Services Card */}
          <div className="bg-gray-800 p-6 rounded-lg">
            <img
              src="https://media.istockphoto.com/id/1199060494/photo/insurance-protecting-family-health-live-house-and-car-concept.jpg?b=1&s=612x612&w=0&k=20&c=rShYMRajPvg2S4u84fR-wNzJjhelKMCeY8HrTMNJQ-c="
              alt="insurance"
              className="object-cover w-full h-64"
            />
            <h3 className="text-2xl font-semibold mb-4">Our Services</h3>
            <ul className="list-disc pl-6">
              <li>Motor Insurance</li>
              <li>Home Insurance</li>
              <li>Life Insurance</li>
              <li>Health Insurance</li>
              <li>Business Insurance</li>
            </ul>
          </div>

          <div className="bg-gray-800 p-6 rounded-lg">
            <h3 className="text-2xl font-semibold mb-4">Motor Insurance Cover</h3>
            <img
              src="https://media.istockphoto.com/id/1264403893/photo/concept-of-insurance-man-protective-and-car-family-health-insurance-policy-examining.jpg?b=1&s=612x612&w=0&k=20&c=6eq8WyEkT2nl1I4ycpqw27ZOF9R0z3PtQHbCgU36uvM="
              alt="insurance"
              className="object-cover w-full h-64"
            />
            <p className='font-semibold mb-4'>Get your Motor Insurance Cover at affordable rates</p>
            <ul className="list-disc pl-6">
              <li>Matatus and Ubers</li>
              <li>General Cartage</li>
              <li>Commercial Own Goods</li>
              <li>MotorCycles</li>
              <li>Tuk Tuks</li>
              <li>Private Cover</li>
            </ul>
          </div>
        </div>
        {/* Stats and Reviews */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 w-full">
          {/* Stats Card */}
          <div className="bg-gray-800 p-6 rounded-lg">
            <img
              src="https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="stats"
              className="object-cover w-full h-64"
            />
            <h3 className="text-2xl font-semibold mb-4">Our Stats</h3>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faCar} className="text-3xl mr-2" />
              <span className="text-xl">500+ Vehicles Insured</span>
            </div>
          </div>

          <div className="bg-gray-800 p-6 rounded-lg">
            <h3 className="text-2xl font-semibold mb-4">Personal Accident Cover</h3>
            <img
              src="https://media.istockphoto.com/id/1199060494/photo/insurance-protecting-family-health-live-house-and-car-concept.jpg?b=1&s=612x612&w=0&k=20&c=rShYMRajPvg2S4u84fR-wNzJjhelKMCeY8HrTMNJQ-c="
              alt="insurance"
              className="object-cover w-full h-64"
            />
            <p className="font-semibold mb-4">Get the following benefits for only KSH2,452 annually</p>
            <ul className="list-disc pl-6">
              <li>Medical Expenses: 50,000</li>
              <li>Artificial Appliances: 50,000</li>
              <li>Temporary Total Disablement: 5,000 weekly</li>
              <li>Permanent Total Disability: 1,000,000</li>
              <li>Accidental Death: 1,000,000</li>
            </ul>
          </div>
        </div>
        {/* Customer Reviews */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8 w-full">
          <div className="bg-gray-800 p-6 rounded-lg">
            <img
              src="https://media.istockphoto.com/id/1386241242/photo/customer-review-satisfaction-feedback-survey-concept-rating-service-experience-on-online.jpg?b=1&s=612x612&w=0&k=20&c=Q-N6vW9cEtbTRwz6B0mObSc6p68xjNCmsT4OZ_ZmyaA="
              alt="stats"
              className="object-cover w-full h-64"
            />
            <h3 className="text-2xl font-semibold mb-4">Customer Reviews</h3>
            <div className="mb-2">
              <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-1" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-1" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-1" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-1" />
              <FontAwesomeIcon icon={faStar} className="text-yellow-400" />
            </div>
            <p className="mb-2">"Great experience! Pemire Insurance helped me find the perfect policy for my needs."</p>
            <p className="text-sm">- John Kamau</p>
          </div>
          <div className="flex justify-center text-center md:text-left md:h-[500px]">
            <div className='flex justify-center items-center'>
              <Link to="/motors">
                <button className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-600">
                  Continue to Pemire Motors
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
